//default namespace for all methods/variables
var base = base || {};
base.isDebugOn = false;
base.rootURL = "https://ba.se";
if (window.location.hostname == "localhost") {
    base.rootURL = 'http://localhost:8888/Echo/public';
}
if (window.location.hostname == "bases.menu") {
    base.rootURL = "https://bases.menu";
}
if ('L' in window) {
    L.mapbox.accessToken = 'pk.eyJ1Ijoid2tyYWZ0IiwiYSI6ImNpbW1lMjV2ODAyeHh0c2x4a2JhZjlxbXUifQ.TRuP1QoklZuTC8gESfNv5Q';
}

//allow global use of console.log, even for browsers that don't support it
var console = console || {};
if (!console.log) { console.log = function() {};}

console.debuglog = function(a1,a2,a3,aN) {
    //log to console only if debug flag is true
    if (base.isDebugOn)
        console.log(arguments);
};

/**
 * SOURCE: https://gomakethings.com/how-to-get-the-value-of-a-querystring-with-native-javascript/
 * Get the value of a querystring
 * @param  {String} field The field to get the value of
 * @param  {String} url   The URL to get the value from (optional)
 * @return {String}       The field value
 */
base.getQueryString = function ( field, url ) {
    var href = url ? url : window.location.href;
    var reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' );
    var string = reg.exec(href);
    return string ? string[1] : null;
};

// get query string parameter value by name
base.getParameterByName = function(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
if (!Object.keys) {
    Object.keys = (function () {
        "use strict";
        var hasOwnProperty = Object.prototype.hasOwnProperty,
            hasDontEnumBug = !({ toString: null }).propertyIsEnumerable("toString"),
            dontEnums = [
              "toString",
              "toLocaleString",
              "valueOf",
              "hasOwnProperty",
              "isPrototypeOf",
              "propertyIsEnumerable",
              "constructor"
            ],
            dontEnumsLength = dontEnums.length;

        return function (obj) {
            if (typeof obj !== "object" && (typeof obj !== "function" || obj === null)) {
                throw new TypeError("Object.keys called on non-object");
            }

            var result = [], prop, i;

            for (prop in obj) {
                if (hasOwnProperty.call(obj, prop)) {
                    result.push(prop);
                }
            }

            if (hasDontEnumBug) {
                for (i = 0; i < dontEnumsLength; i++) {
                    if (hasOwnProperty.call(obj, dontEnums[i])) {
                        result.push(dontEnums[i]);
                    }
                }
            }
            return result;
        };
    }());
}

base.xhr = function (options) {
    ///Create a new xhr object, passing in an object of all options, and then call fire();
    options = options || {};

    if (!options.webMethodPath)
        options.webMethodPath = "/";
    if (!options.webMethodName)
        options.webMethodName = "";
    if (!options.apiUrlBase)
        options.apiUrlBase = base.hive.apiUrlBase;
    if (!options.requestVerb)
        options.requestVerb = "GET";
    if (!options.data)
        options.data = {}; //options.data is the object that gets passed up to the server

    //add sessionId to all calls, unless specifically told not to
    if (!options.data.sessionId && !options.ignoreSessionId)
        options.data.sessionId = base.user.sessionId;

    //called when the request returns successfully with data
    var handleSuccess = options.handleSuccess || function (data) {
        console.log(options.webMethodName + " success", data);

        if (options.callbackSuccess)
            options.callbackSuccess(data.Data || data);
    };

    //called when the request fails, or no data is returned
    var handleFail = options.handleFail || function (data) {
        console.log(options.webMethodName + " fail", data);

        if (options.callbackFailure)
            options.callbackFailure(data);
    };

    //called when the request comes back, and determines whether or not to route to success/fail
    var handleDone = options.handleDone || function (data) {
        if (typeof data === "undefined"
            || data == null
            || (data && data.IsError)
            || (data && data.ErrorMessage && data.ErrorMessage !== "")
            ) {
            return handleFail(data);
        }
        return handleSuccess(data);
    };

    //optionally always called after a request is completed
    var handleAlways = options.handleAlways || function() {
        if (options.callbackAlways)
            options.callbackAlways();
    };

    //called by the user to send the request to the server
    this.fire = function() {
        var jqxhr = $.ajax({
            context: document.body,
            type: options.requestVerb,
            url: options.apiUrlBase + options.webMethodPath + options.webMethodName,
            data: options.data
        }).done(function(data) {
            handleDone(data);
        }).fail(function(data) {
            handleFail(data);
        }).always(function() {
            handleAlways();
        });
    };

    //always return null - this is an async method
    return null;
};

// Google AdWords Conversion Tracking cookie
// base.readCookie = function (name) { 
//     var n = name + "="; 
//     var cookie = document.cookie.split(';'); 
//     for(var i=0;i < cookie.length;i++) {      
//         var c = cookie[i];      
//         while (c.charAt(0)==' '){c = c.substring(1,c.length);}      
//         if (c.indexOf(n) == 0){return c.substring(n.length,c.length);} 
//     } 
//     return null; 
// } 

// base.setCookie = function (cname, cvalue, exdays) {
//     var d = new Date();
//     d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
//     var expires = "expires=" + d.toUTCString();
//     document.cookie = cname + "=" + cvalue + "; " + expires;
// };

// base.getCookie = function (cname) {
//     var name = cname + "=";
//     var ca = document.cookie.split(';');
//     for(var i = 0; i <ca.length; i++) {
//         var c = ca[i];
//         while (c.charAt(0)==' ') {
//             c = c.substring(1);
//         }
//         if (c.indexOf(name) == 0) {
//             return c.substring(name.length,c.length);
//         }
//     }
//     return "";
// }

// base.deleteCookie = function (cname) {
//     document.cookie = cname + "=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
// }